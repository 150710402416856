<template>
  <el-select
    :model-value="modelValue"
    class="w-32"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <el-option
      v-for="option in options"
      :key="option.value"
      :label="option.label"
      :value="option.value"
    />
  </el-select>
</template>

<script>
export default {
  name: 'TimeTypeSelect',
  props: {
    modelValue: {
      type: String,
      required: true
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      options: [
        {
          value: 'day',
          label: this.$t(
            'pages.settings.reservations.customization.limits.types.day'
          )
        },
        {
          value: 'hour',
          label: this.$t(
            'pages.settings.reservations.customization.limits.types.hours'
          )
        },
        {
          value: 'month',
          label: this.$t(
            'pages.settings.reservations.customization.limits.types.month'
          )
        }
      ]
    }
  }
}
</script>
