<template>
  <el-upload
    ref="uploader"
    class="upload-logo"
    drag
    :limit="1"
    :auto-upload="false"
    :show-file-list="false"
    :on-change="handleFileUpload"
    :accept="acceptAttribute"
  >
    <el-icon>
      <Upload />
    </el-icon>

    <div class="el-upload__text">
      {{ $t('components.image_upload.drag_drop') }}

      <span class="text-th-primary">{{
        $t('components.image_upload.click_to_upload')
      }}</span>

      <p class="mb-0 mt-2 text-xs">
        <slot name="hint">
          {{ $t('components.image_upload.size_message.short') }}
        </slot>
      </p>
    </div>

    <div v-if="value" class="absolute inset-0 bg-white">
      <img class="object-contain w-full h-full" :src="value" alt="logo" />

      <el-button
        class="img-delete-btn"
        icon="Delete"
        text
        @click.stop="handleRemove"
      />
    </div>
  </el-upload>
</template>

<script>
import { defineComponent } from 'vue'
import { readFileAsDataURL } from './utils'

/**
 * @typedef {object} ImageUploadEvent
 * @property {File} file
 * @property {() => Promise<string | ArrayBuffer>} readAsDataURL Returns a base64 encoded url for the image
 */

export default defineComponent({
  name: 'ImageUploadInput',
  props: {
    /**
     * The url of the image, that will be displayed in the component
     * `update:modelValue` is emitted if the remove button is clicked
     * Use the `upload` event to handle the newly uploaded image
     */
    modelValue: {
      type: String,
      default: null
    },
    /**
     * Maximum size of the uploaded file in kilobytes
     */
    maxFileSize: {
      type: Number,
      default: Infinity
    },
    /**
     * Maximum width of the image in pixels
     */
    maxWidth: {
      type: Number,
      default: undefined
    },
    /**
     * Maximum height of the image in pixels
     */
    maxHeight: {
      type: Number,
      default: undefined
    },
    /**
     * Minimum width of the image in pixels
     */
    minWidth: {
      type: Number,
      default: undefined
    },
    /**
     * Minimum height of the image in pixels
     */
    minHeight: {
      type: Number,
      default: undefined
    },
    /**
     * A list of allowed file extensions for the image
     * Example: ['jpg', 'png']
     */
    extensions: {
      type: Array,
      default: () => []
    }
  },
  emits: ['update:modelValue', 'upload'],
  computed: {
    acceptAttribute() {
      return this.extensions.length === 0
        ? 'image/*'
        : this.extensions.map((extension) => '.' + extension).join(',')
    },
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },

  methods: {
    handleRemove() {
      this.value = null
    },

    /**
     * Used to display error messages related to the uploaded file
     */
    notify(message) {
      this.$message({
        type: 'error',
        message
      })
    },

    /**
     * @param {File} file
     * @returns {boolean}
     */
    validateExtension(file) {
      if (this.extensions.length === 0) {
        return true
      }

      const fileExtension = file.name.split('.').pop().toLowerCase()

      return this.extensions.includes(fileExtension)
    },

    /**
     * @param {File} file
     * @returns {boolean}
     */
    validateFilesize(file) {
      return file.size < this.maxFileSize * 1024
    },

    /**
     * @param {File} file
     * @returns {boolean}
     */
    validateDimensions(file) {
      return new Promise((resolve, reject) => {
        const image = new Image()

        function cleanUp() {
          image.onload = null
          image.onerror = null
          setTimeout(() => {
            URL.revokeObjectURL(image.src)
          })
        }

        image.onload = () => {
          cleanUp()
          const { minWidth, minHeight, maxWidth, maxHeight } = this
          const { width, height } = image

          if (minWidth && width < minWidth) {
            this.notify(
              this.$t(
                'components.image_upload.error_message.image_width_too_small',
                {
                  minWidth
                }
              )
            )

            return resolve(false)
          }

          if (minHeight && height < minHeight) {
            this.notify(
              this.$t(
                'components.image_upload.error_message.image_height_too_small',
                {
                  minHeight
                }
              )
            )

            return resolve(false)
          }

          if (maxHeight && maxHeight > height) {
            this.notify(
              this.$t(
                'components.image_upload.error_message.image_height_too_large',
                {
                  maxHeight
                }
              )
            )

            return resolve(false)
          }

          if (maxWidth && maxWidth > width) {
            this.notify(
              this.$t(
                'components.image_upload.error_message.image_width_too_large',
                {
                  maxWidth
                }
              )
            )
            return resolve(false)
          }

          return resolve(true)
        }

        image.onerror = (error) => {
          cleanUp()
          reject(error)
        }

        image.src = URL.createObjectURL(file)
      })
    },

    /**
     * @param {File} file
     * @returns {ImageUploadEvent}
     */
    createImageUploadEvent(file) {
      return {
        file,
        async readAsDataURL() {
          return readFileAsDataURL(file)
        }
      }
    },

    async handleFileUpload(event) {
      const { raw: file } = event

      this.$refs.uploader.clearFiles()

      if (!this.validateExtension(file)) {
        return this.notify(
          this.$t(
            'components.image_upload.error_message.invalid_file_extension',
            {
              allowedExtensions: this.extensions.join(', ')
            }
          )
        )
      }

      if (!this.validateFilesize(file)) {
        return this.notify(
          this.$t('components.image_upload.error_message.file_size_too_large', {
            max: this.maxFileSize + 'kb'
          })
        )
      }

      try {
        const areDimensionsValid = await this.validateDimensions(file)

        if (!areDimensionsValid) {
          return
        }
      } catch (error) {
        return this.notify(error.message)
      }

      this.$emit('upload', this.createImageUploadEvent(file))
    }
  }
})
</script>

<style scoped>
.upload-logo :deep(.el-upload--picture) {
  width: 100%;
}

.upload-logo :deep(.el-upload-dragger) {
  width: 100%;
}

.img-delete-btn {
  font-size: 20px;
  padding: 10px;
  position: absolute;
  right: 0;
  top: 0;
}

.img-delete-btn:hover {
  color: red;
}

.image-upload-text {
  margin: 0;
  display: flex;
  flex-direction: column;
}

.image-upload-text :deep(p) {
  margin: 0;
  color: #a7abb1;
}
</style>
