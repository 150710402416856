<template>
  <div class="flex">
    <el-input
      :model-value="localValue.value"
      class="w-24"
      type="number"
      :min="1"
      @update:model-value="onInput($event)"
      @change="syncModelValue"
    />

    <time-type-select
      v-model="localValue.type"
      class="ml-4"
      @change="syncModelValue"
    />
  </div>
</template>
<script>
import { reactive } from 'vue'
import TimeTypeSelect from './time-type-select'
export default {
  name: 'TimeIntervalInput',
  components: {
    TimeTypeSelect
  },
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  emits: ['update:modelValue'],
  computed: {
    localValue() {
      return reactive({ ...this.modelValue })
    }
  },
  methods: {
    onInput(value) {
      value = Number(value.trim())

      this.localValue.value = Number.isNaN(value) || value <= 0 ? 1 : value
    },
    syncModelValue() {
      this.$emit('update:modelValue', {
        ...this.localValue
      })
    }
  }
}
</script>
