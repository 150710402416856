<template>
  <el-form
    ref="form"
    :model="form"
    :rules="rules"
    data-testid="add-closing-days"
  >
    <el-row :gutter="20">
      <el-col :md="9" :lg="8">
        <el-form-item prop="reason">
          <el-input
            v-model="form.reason"
            :placeholder="
              $t(
                'pages.settings.reservations.closing_days.form.reason.placeholder'
              )
            "
          />
        </el-form-item>
      </el-col>

      <el-col :md="10" :lg="8">
        <el-form-item ref="dateRangeFormItem" prop="date_range">
          <el-popover
            v-model:visible="isDatePickerOpen"
            trigger="focus"
            popper-class="w-auto"
          >
            <template #reference>
              <el-input
                class="date-picker-input"
                readonly
                :value="
                  form.date_range.start
                    ? formatDateRange(form.date_range)
                    : undefined
                "
                :placeholder="
                  $t(
                    'pages.settings.reservations.closing_days.form.date_range.placeholder'
                  )
                "
                prefix-icon="Calendar"
                @focus="isDatePickerOpen = true"
              />
            </template>

            <date-range-picker
              :key="datePickerKey"
              :min-date="minDate"
              :selected-date="form.date_range"
              :locale="$i18n.locale"
              @update="updateFormDate"
            />

            <footer class="text-right mt-2">
              <el-button @click="isDatePickerOpen = false">
                {{ $t('common.interactions.buttons.close') }}
              </el-button>
            </footer>
          </el-popover>
        </el-form-item>
      </el-col>

      <el-col :md="5">
        <el-button
          class="submit-button mb-4"
          type="primary"
          :disabled="
            form.date_range.start === null && form.date_range.end === null
          "
          @click="handleSubmit"
        >
          {{ $t('common.interactions.buttons.add') }}
        </el-button>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import { formatDateRange, formatDateToISO } from '@/utils/date/index'
import DateRangePicker from '@/components/date-picker/range/date-range-picker.vue'

export default {
  name: 'AddClosingDaysForm',
  components: {
    DateRangePicker
  },
  emits: ['submit'],
  data() {
    return {
      // VCalendar seems to have an issue, when we clear the model value
      // the old date range remains selected for some reason. We use key to
      // rebuild it after submit
      datePickerKey: 0,
      minDate: new Date(),
      isDatePickerOpen: false,
      form: this.getDefaultFormValue(),
      rules: {
        reason: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required')
          }
        ],
        date_range: [
          {
            validator: this.validateDateRange,
            // Cannot trigger this on blur, because user focuses input to open datepicker
            // and when he clicks on the first date, it will trigger a validation
            trigger: 'change'
          }
        ]
      }
    }
  },

  methods: {
    validateDateRange(_, dateRange, callback) {
      if (!dateRange.start || !dateRange.end) {
        return callback(
          new Error(this.$t('common.forms.rules.field_warnings.required'))
        )
      }

      callback()
    },
    updateFormDate(start, end) {
      this.form.date_range.start = start
      this.form.date_range.end = end
      this.$refs.dateRangeFormItem.validate()
    },
    handleSubmit() {
      this.$refs.form.validate((isValid) => {
        if (!isValid) {
          return this.$message({
            type: 'error',
            message: this.$t(
              'common.forms.rules.field_warnings.invalid_inputs.required'
            )
          })
        }

        const form = this.form

        this.$emit('submit', {
          reason: form.reason,
          start_date: formatDateToISO(form.date_range.start),
          end_date: formatDateToISO(form.date_range.end)
        })

        this.form = this.getDefaultFormValue()

        this.$nextTick(() => {
          this.datePickerKey++
        })

        this.$refs.form.resetFields()
      })
    },
    getDefaultFormValue() {
      return {
        reason: this.$t(
          'pages.settings.reservations.closing_days.form.reason.default_value'
        ),
        date_range: {
          start: null,
          end: null
        }
      }
    },
    formatDateRange
  }
}
</script>

<style scoped>
.submit-button {
  margin-top: 2px;
}
</style>
