<template>
  <el-row :gutter="20" data-testid="add-holidays-form">
    <el-col :span="18" :md="9" :lg="12">
      <el-select
        v-model="selectedRegionCode"
        class="w-full"
        :placeholder="
          $t(
            'pages.settings.reservations.closing_days.select_holidays.placeholder'
          )
        "
        filterable
      >
        <el-option
          v-for="region in regions"
          :key="region.code"
          :value="region.code"
          :label="region.name"
        />
      </el-select>
    </el-col>

    <el-col :span="6" :md="10" :lg="8">
      <el-button
        :disabled="!selectedRegionCode"
        type="primary"
        @click="handleAdd"
      >
        {{ $t('common.interactions.buttons.add') }}
      </el-button>
    </el-col>
  </el-row>
</template>

<script setup>
import { ref, defineProps, defineEmits, computed } from 'vue'
import GermanyStatesIsoCodes from './germany-states-iso-codes.json'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  resources: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(['submit'])

const { t } = useI18n()

const regions = computed(() => {
  const regions = [{ name: 'Austria', code: 'AT' }, ...GermanyStatesIsoCodes]

  return regions
    .filter(({ code }) => !!props.resources.holidays[code])
    .map(({ code, name }) => {
      const holidays = props.resources.holidays[code]

      const nameLocalized = t(
        'pages.settings.reservations.closing_days.region_holidays',
        {
          region: name
        }
      )

      return {
        name: `${nameLocalized} ${getHolidaysYearRange(holidays)}`,
        code
      }
    })
})

const selectedRegionCode = ref()

function getHolidaysYearRange(holidays) {
  const first = holidays[0]
  const last = holidays[holidays.length - 1]

  return [
    new Date(first.date).getFullYear(),
    new Date(last.date).getFullYear()
  ].join('-')
}

function handleAdd() {
  const holidays = props.resources.holidays[selectedRegionCode.value] || []

  if (holidays.length === 0) {
    return
  }

  const closingDays = holidays.map((holiday) => ({
    reason: holiday.localName,
    start_date: holiday.date,
    end_date: holiday.date
  }))

  emit('submit', closingDays)

  selectedRegionCode.value = undefined
}
</script>
