<template>
  <th-wrapper :title="$t('pages.settings.reservations.opening_hours.title')">
    <template #subtitle>
      <multi-line-text
        :text="$t('pages.settings.reservations.opening_hours.explainer')"
      />
    </template>

    <template #default>
      <opening-hours-form ref="form" v-model="openingHours" />
    </template>
  </th-wrapper>
</template>

<script>
import safeGet from 'just-safe-get'
import MultiLineText from '@/components/multi-line-text'
import OpeningHoursForm from './opening-hours-form.vue'

export default {
  name: 'OpeningHours',
  components: { MultiLineText, OpeningHoursForm },
  computed: {
    openingHours: {
      get() {
        return safeGet(
          this.$store.state.Config.clientAccountConfiguration,
          'reservations.opening_hours'
        )
      },
      set(value) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: `reservations.opening_hours`,
          value
        })
      }
    }
  },
  methods: {
    /**
     * @public
     */
    async validate() {
      return this.$refs.form.validate()
    }
  }
}
</script>
