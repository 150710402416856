<template>
  <div>
    <add-closing-days-form @submit="addClosingDays" />

    <p class="mb-4 mt-0 text-sm">
      {{ $t('pages.settings.reservations.closing_days.and_or') }}
    </p>

    <holidays-form :resources="resources" @submit="addHolidays" />

    <closing-days-table
      :closing-days="closingDays"
      @remove="removeClosingDay"
    />
  </div>
</template>

<script>
import ClosingDaysTable from './closing-days-table.vue'
import AddClosingDaysForm from './add-closing-days-form.vue'
import HolidaysForm from './holidays-form.vue'

export default {
  name: 'ClosingDaysForm',
  components: { ClosingDaysTable, AddClosingDaysForm, HolidaysForm },
  props: {
    modelValue: {
      type: Array,
      required: true
    },
    /**
     * Resources containing the holidays object
     */
    resources: {
      type: Object,
      required: true
    }
  },
  computed: {
    closingDays() {
      return [...this.modelValue]
    }
  },
  methods: {
    getUniqueClosingDays(closingDays) {
      const uniqueKeys = new Set()
      const uniqueClosingDays = []

      closingDays.forEach((closingDay) => {
        const { start_date, end_date } = closingDay
        const key = [start_date, end_date].join('-')

        if (uniqueKeys.has(key)) {
          return
        }

        uniqueKeys.add(key)
        uniqueClosingDays.push(closingDay)
      })

      return uniqueClosingDays
    },
    addHolidays(holidays) {
      this.addClosingDays(...holidays)
    },
    addClosingDays(...closingDaysToAdd) {
      // Create a copy of the current closing days, add the new ones
      // and sort them by start date, so the dont appear out of order
      const value = this.closingDays.concat(closingDaysToAdd).sort((a, b) => {
        return new Date(a.start_date) - new Date(b.start_date)
      })

      this.$emit('update:modelValue', this.getUniqueClosingDays(value))
    },
    removeClosingDay(indexToRemove) {
      const value = this.closingDays.filter((_, index) => {
        return index !== indexToRemove
      })

      this.$emit('update:modelValue', value)
    }
  }
}
</script>
