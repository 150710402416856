<template>
  <th-wrapper
    v-loading="loading"
    :title="$t('pages.settings.reservations.closing_days.title')"
    :subtitle="$t('pages.settings.reservations.closing_days.explainer')"
  >
    <closing-days-form v-model="closingDays" :resources="resources" />
  </th-wrapper>
</template>

<script>
import safeGet from 'just-safe-get'
import th from '@tillhub/javascript-sdk'
import ClosingDaysForm from './closing-days-form.vue'

export default {
  name: 'ClosingDays',
  components: { ClosingDaysForm },
  data() {
    return {
      loading: false,
      resources: {
        holidays: {}
      }
    }
  },
  computed: {
    closingDays: {
      get() {
        return safeGet(
          this.$store.state.Config.clientAccountConfiguration,
          'reservations.closing_days',
          []
        )
      },
      set(value) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: `reservations.closing_days`,
          value
        })
      }
    }
  },
  async created() {
    await this.fetchResources()
  },
  methods: {
    async fetchResources() {
      try {
        this.loading = true

        const { holidays = {} } = await this.$resourceFetch({
          resource: 'holidays',
          handler: () => th.holidays().getAll()
        })

        this.resources.holidays = holidays
      } catch (error) {
        this.$logException(error, { trackError: false })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
