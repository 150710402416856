<template>
  <div class="inline-block max-w-full">
    <hr class="my-6" />

    <el-input
      v-model="searchQuery"
      :placeholder="$t('common.inputs.placeholders.search')"
      clearable
    />

    <div class="max-w-full overflow-hidden">
      <el-table :data="filteredClosingDays" max-height="600px" max-width="100%">
        <el-table-column
          :label="$t('pages.settings.reservations.closing_days.headers.reason')"
          prop="reason"
          width="250"
        />

        <el-table-column
          :label="
            $t('pages.settings.reservations.closing_days.headers.from_date')
          "
          :formatter="(row) => formatDate(row.start_date)"
          width="125"
        />

        <el-table-column
          :label="
            $t('pages.settings.reservations.closing_days.headers.to_date')
          "
          :formatter="(row) => formatDate(row.end_date)"
          width="125"
        />

        <el-table-column v-slot="{ row }" width="64">
          <el-button
            class="w-10"
            type="danger"
            icon="Delete"
            circle
            @click="handleRemove(row)"
          />
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script setup>
import { formatDate } from '@/utils/date/index'
import { computed, defineProps, defineEmits, ref } from 'vue'

const props = defineProps({
  closingDays: {
    type: Array,
    required: true
  }
})

const emit = defineEmits(['remove'])

const searchQuery = ref('')

const filteredClosingDays = computed(() => {
  const q = searchQuery.value.toLowerCase()

  if (!q.length) {
    return props.closingDays
  }

  return props.closingDays.filter(({ reason }) => {
    return reason.toLowerCase().includes(q)
  })
})

function handleRemove(row) {
  // Find the real index from the original data, as table rows
  // can be filtered
  const realIndex = props.closingDays.indexOf(row)

  emit('remove', realIndex)
}
</script>
