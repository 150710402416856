<template>
  <el-select
    :model-value="modelValue === null ? Infinity : modelValue"
    @update:model-value="
      $emit('update:modelValue', $event === Infinity ? null : $event)
    "
  >
    <el-option
      v-for="option in options"
      :key="option.value"
      :label="option.label"
      :value="option.value"
    />
  </el-select>
</template>

<script>
export default {
  name: 'ServicesPerAppointmentSelect',
  props: {
    modelValue: {
      type: [Number, null],
      required: true
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      options: [
        {
          // ElOption does not support Null as a value prop
          // We use Infinity internally, but always emit Null to parent
          value: Infinity,
          label: this.$t(
            'pages.settings.reservations.customization.services_per_appointment.unlimited'
          )
        },
        ...Array.from({ length: 10 }).map((_, index) => {
          return {
            label: index + 1,
            value: index + 1
          }
        })
      ]
    }
  }
}
</script>
