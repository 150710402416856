<template>
  <th-wrapper :title="$t('pages.settings.reservations.customization.title')">
    <template #subtitle>
      <multi-line-text
        :text="$t('pages.settings.reservations.customization.explainer')"
      />
    </template>

    <template #default>
      <el-form ref="form" :model="form" :rules="rules">
        <th-input-title class="mb-4 p-0">
          <el-switch v-model="reservationsPageActivated" class="mr-4" />

          {{
            $t(
              'pages.settings.reservations.customization.reservations_page.label'
            )
          }}
        </th-input-title>

        <div v-if="reservationsPageActivated" class="text-sm my-6">
          {{
            $t(
              'pages.settings.reservations.customization.reservations_page.link_label'
            )
          }}
          <a
            class="text-th-primary"
            :href="reservationsPageUrl"
            target="_blank"
          >
            {{ reservationsPageUrl }}
          </a>
        </div>

        <el-row :gutter="16">
          <el-col class="pb-4" :lg="8" :md="12" :sm="14">
            <th-input-title>
              {{ $t('pages.settings.reservations.customization.logo.label') }}
            </th-input-title>

            <image-upload-input
              v-model="logo"
              :max-file-size="500"
              :extensions="['jpeg', 'jpg', 'png']"
              :min-height="logoMinHeight"
              @upload="handleLogoUpload"
            >
              <template #hint>
                {{ $t('components.image_upload.size_message.long') }} <br />

                {{
                  $t('components.image_upload.size_message.min_height_hint', {
                    minHeight: logoMinHeight
                  })
                }}
              </template>
            </image-upload-input>
          </el-col>

          <el-col class="pb-4 el-col--color">
            <th-input-title>
              {{ $t('pages.settings.reservations.customization.color.label') }}
            </th-input-title>
            <div class="color-picker">
              <el-color-picker
                v-model="color"
                :show-alpha="false"
                color-format="hex"
              />
            </div>
          </el-col>
        </el-row>

        <div class="space-y-6">
          <reservation-frequency-field v-model="reservationFrequencyMinutes" />

          <div>
            <th-input-title>
              {{
                $t(
                  'pages.settings.reservations.customization.reservation_limits.label'
                )
              }}
            </th-input-title>

            <div class="mb-4">
              <div class="flex items-center">
                <th-input-title class="p-0 mr-4 w-16">
                  {{
                    $t(
                      'pages.settings.reservations.customization.reservation_limits.min'
                    )
                  }}
                </th-input-title>

                <el-form-item
                  class="mb-0"
                  prop="minBookingLeadTime"
                  :show-message="false"
                >
                  <time-interval-input
                    v-model="minBookingLeadTime"
                    data-testid="min-booking-lead-time"
                    @update:model-value="validate"
                  />
                </el-form-item>

                <span class="text-sm ml-4">
                  {{
                    $t(
                      'pages.settings.reservations.customization.reservation_limits.before_appointment_hint'
                    )
                  }}
                </span>
              </div>

              <el-form-item
                v-if="isMinBookingLeadingTimeInvalid"
                class="mt-1 pt-1 mb-8 md:mb-6"
                prop="minBookingLeadTime"
              />
            </div>

            <div class="flex items-center">
              <th-input-title class="p-0 mr-4 w-16">
                {{
                  $t(
                    'pages.settings.reservations.customization.reservation_limits.max'
                  )
                }}
              </th-input-title>

              <time-interval-input
                v-model="maxBookingLeadTime"
                @update:model-value="validate"
              />

              <span class="text-sm ml-4">
                {{
                  $t(
                    'pages.settings.reservations.customization.reservation_limits.before_appointment_hint'
                  )
                }}
              </span>
            </div>
          </div>

          <div>
            <th-input-title>
              {{
                $t(
                  'pages.settings.reservations.customization.cancellation_limit.label'
                )
              }}
            </th-input-title>

            <time-interval-input v-model="cancellationLeadTime" />

            <multi-line-text
              class="text-xs mt-2"
              :text="
                $t(
                  'pages.settings.reservations.customization.cancellation_limit.explainer'
                )
              "
            />
          </div>

          <div>
            <th-input-title>
              {{
                $t(
                  'pages.settings.reservations.customization.services_per_appointment.label'
                )
              }}
            </th-input-title>

            <services-per-appointment-select
              v-model="maxServicesPerAppointment"
              class="w-32"
            />

            <span class="block mt-2 text-xs">{{
              $t(
                'pages.settings.reservations.customization.services_per_appointment.explainer'
              )
            }}</span>
          </div>

          <div>
            <th-input-title>
              {{
                $t(
                  'pages.settings.reservations.customization.employee_selection.label'
                )
              }}
            </th-input-title>

            <th-input-title class="p-0 flex items-center">
              <el-switch v-model="allowEmployeeSelection" class="mr-4" />

              {{
                $t(
                  allowEmployeeSelection
                    ? 'common.forms.labels.active'
                    : 'common.forms.labels.inactive'
                )
              }}
            </th-input-title>

            <multi-line-text
              class="text-xs mt-2"
              :text="
                $t(
                  'pages.settings.reservations.customization.employee_selection.explainer'
                )
              "
            />
          </div>
        </div>
      </el-form>
    </template>
  </th-wrapper>
</template>

<script>
import safeGet from 'just-safe-get'
import ImageUploadInput, { ImageUploadEvent } from './image-upload-input.vue'
import ReservationFrequencyField from './reservation-frequency-field.vue'
import ServicesPerAppointmentSelect from './services-per-appointment-select.vue'
import TimeIntervalInput from './time-interval-input.vue'
import MultiLineText from '@/components/multi-line-text'

export default {
  name: 'Customization',
  components: {
    ReservationFrequencyField,
    ImageUploadInput,
    ServicesPerAppointmentSelect,
    TimeIntervalInput,
    MultiLineText
  },
  data() {
    return {
      isMinBookingLeadingTimeInvalid: false,
      rules: {
        minBookingLeadTime: [{ validator: this.validateMinBookingTime }]
      },
      logoMinHeight: 112
    }
  },
  computed: {
    /**
     * We pass this to <el-form /> so that the validation works, but we will use
     * computed properties for updating the value as they are stored in vuex
     */
    form() {
      return {
        minBookingLeadTime: this.minBookingLeadTime
      }
    },
    reservationsPageUrl() {
      const organization = this.$store.state.Auth.orgName

      if (process.env.NODE_ENV !== 'production') {
        return `https://staging-online-booking.tillhub.com/${organization}`
      }

      return `https://online-booking.tillhub.com/${organization}`
    },
    settings() {
      const value = safeGet(
        this.$store.state.Config.clientAccountConfiguration,
        'reservations.customization',
        {}
      )

      return value
    },
    reservationsPageActivated: {
      get() {
        return this.settings.reservations_page_activated
      },
      set(value) {
        this.$ampli.eventWithBaseProps('onlineBookingToggled', {
          is_activated: value
        })

        this.commitChanges('reservations_page_activated', value)
      }
    },
    color: {
      get() {
        return safeGet(this.settings, 'theme.color')
      },
      set(value) {
        this.commitChanges('theme.color', value)
      }
    },
    logo: {
      get() {
        return safeGet(this.settings, 'theme.logo')
      },
      set(value) {
        this.commitChanges('theme.logo', value)
      }
    },
    reservationFrequencyMinutes: {
      get() {
        return this.settings.reservation_frequency_minutes
      },
      set(value) {
        this.commitChanges('reservation_frequency_minutes', value)
      }
    },
    minBookingLeadTime: {
      get() {
        return this.settings.min_booking_lead_time || {}
      },
      set(value) {
        this.commitChanges('min_booking_lead_time', value)
      }
    },
    maxBookingLeadTime: {
      get() {
        return this.settings.max_booking_lead_time || {}
      },
      set(value) {
        this.commitChanges('max_booking_lead_time', value)
      }
    },
    cancellationLeadTime: {
      get() {
        return this.settings.cancellation_lead_time || {}
      },
      set(value) {
        this.commitChanges('cancellation_lead_time', value)
      }
    },
    maxServicesPerAppointment: {
      get() {
        return this.settings.max_services_per_appointment
      },
      set(value) {
        this.commitChanges('max_services_per_appointment', value)
      }
    },
    allowEmployeeSelection: {
      get() {
        return this.settings.allow_employee_selection
      },
      set(value) {
        this.commitChanges('allow_employee_selection', value)
      }
    }
  },
  methods: {
    async validate() {
      return new Promise((resolve) => {
        this.$refs.form.validate(resolve)
      })
    },
    /**
     * @param {{ type: 'day' | 'hour' | 'month', value: number}} timeInterval
     */
    getTimeIntervalValueInHours({ value, type }) {
      let valueInHours = value

      if (type === 'month') {
        // We use 31 days here as the worst case scenario
        valueInHours = value * 31 * 24
      } else if (type === 'day') {
        // 2 days => 48 hours
        valueInHours = value * 24
      }

      return valueInHours
    },

    /**
     * Validates that minBookingTime is set to a value less than maxBookingTime
     */
    async validateMinBookingTime(_, __, callback) {
      const minValueInHours = this.getTimeIntervalValueInHours(
        this.minBookingLeadTime
      )

      const maxValueInHours = this.getTimeIntervalValueInHours(
        this.maxBookingLeadTime
      )

      this.isMinBookingLeadingTimeInvalid = minValueInHours > maxValueInHours

      if (this.isMinBookingLeadingTimeInvalid) {
        return callback(
          this.$t(
            'pages.settings.reservations.customization.errors.min_booking_time_is_greater_than_max_booking_time'
          )
        )
      }

      callback()
    },
    /**
     * @param {ImageUploadEvent} event
     */
    async handleLogoUpload(event) {
      this.logo = await event.readAsDataURL()
    },

    commitChanges(field, value) {
      this.$store.dispatch('Config/setClientAccountConfigurationValue', {
        path: `reservations.customization.${field}`,
        value
      })
    }
  }
}
</script>

<style scoped>
/* 
 * When a day is marked as closed, the select inputs are hidden.
 * This CSS helps normalize the heights of the cells before/after hide
 */
.color-picker:deep(.el-color-picker__trigger) {
  width: 64px;
}

@media screen and (min-width: 1200px) {
  .el-col--color {
    flex: auto;
    flex-shrink: 0;
    flex-grow: 0;
  }
}
</style>
