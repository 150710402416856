<script setup>
import { defineProps, computed } from 'vue'

const props = defineProps({
  /**
   * The text to display.
   * The component splits this on `\n` symbols, you should use Enter in phrase
   * to create new lines in your translation
   */
  text: {
    type: String,
    required: true
  }
})

const lines = computed(() => props.text.split('\n'))
</script>
<template>
  <div class="whitespace-normal">
    <span v-for="line in lines" :key="line" class="block">{{ line }}</span>
  </div>
</template>
