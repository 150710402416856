<template>
  <div>
    <div class="flex items-center">
      <th-input-title class="p-0 mr-4">
        {{
          $t(
            'pages.settings.reservations.customization.reservation_frequency.label'
          )
        }}
      </th-input-title>

      <el-select
        :model-value="modelValue"
        @update:model-value="$emit('update:modelValue', $event)"
      >
        <el-option
          v-for="option in options"
          :key="option.value"
          :label="option.label"
          :value="option.value"
        />
      </el-select>
    </div>

    <p class="text-xs mt-2">
      {{
        $t(
          'pages.settings.reservations.customization.reservation_frequency.hint_1'
        )
      }}
      <br />
      {{
        $t(
          'pages.settings.reservations.customization.reservation_frequency.hint_2'
        )
      }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'ReservationFrequencyField',
  props: {
    modelValue: {
      type: Number,
      required: true
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      options: Array.from({ length: 12 }).map((_, index) => {
        // 5 minute intervals, 5 -> 60
        const value = 5 * (index + 1)
        return {
          label: this.$t(
            'pages.settings.reservations.customization.reservation_frequency.options.minutes',
            {
              value
            }
          ),
          value
        }
      })
    }
  }
}
</script>
