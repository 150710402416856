<template>
  <div>
    <opening-hours ref="openingHours" />

    <closing-days />

    <customization ref="customization" />
  </div>
</template>

<script>
import ClosingDays from './components/closing-days/index.vue'
import OpeningHours from './components/opening-hours/index.vue'
import Customization from './components/customization/index.vue'

export default {
  name: 'ReservationsIndex',
  components: {
    ClosingDays,
    OpeningHours,
    Customization
  },
  metaInfo() {
    return {
      title: this.$t('pages.settings.reservations.title')
    }
  },
  methods: {
    /**
     * @public
     */
    async validate(callback) {
      // There are multiple sections here, each with its own form.
      // We validate all of them and pass the result to the caller
      const results = await Promise.all([
        this.$refs.openingHours.validate(),
        this.$refs.customization.validate()
      ])

      const isValid = results.every((isValid) => isValid === true)

      callback(isValid)
    }
  }
}
</script>

<style scoped>
/* 
 * When a day is marked as closed, the select inputs are hidden.
 * This CSS helps normalize the heights of the cells before/after hide
 */
.working-hours-table:deep(tbody td) {
  height: 61px;
}
</style>
