/**
 * @param {File} file
 * @returns {string | ArrayBuffer}
 */
export function readFileAsDataURL(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    function cleanUp() {
      reader.onload = null
      reader.onerror = null
    }

    reader.onload = () => {
      cleanUp()
      resolve(reader.result)
    }

    reader.onerror = (err) => {
      cleanUp()
      reject(err)
    }

    reader.readAsDataURL(file)
  })
}
